<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:15px; color:#333; }
.keyword{width:200px;}
.red{color:red;}
.fee_memo{font-size:12px; color:#88001b;}
.block td{background-color:#e6e6e6 !important}
.expired td{background-color:#fff0ed !important}
.btn_location{font-size:20px; color:#099831; padding-right:5px; cursor:pointer; color:#ccc}
.edit_dialog{height:auto; margin:15vh auto !important;}
.edit_dialog .el-row:nth-of-type(n+2){margin-top:20px;}
.edit_dialog .label{font-size:15px; color:#909399;}

.map_dialog{height:90%; margin:5vh auto !important;}
.map_dialog .el-dialog__body{padding:0;}
.map_dialog .address{font-size:22px; font-weight: bold; color:#666; padding-right:30px;}
#map{height:100%;}
</style>
<template>
    <div class="component estate">
        <!-- 编辑框 -->
        <el-dialog width="550px" custom-class="edit_dialog" :title="data_for_edit.id>0 ? '编辑小区' : '添加小区'" v-model="show_edit" :close-on-click-modal="false">
            <el-row :gutter="10" class="label">
                <el-col :span="4" class="red">小区名称*</el-col>
                <el-col :span="20">
                    <el-input v-model="data_for_edit.name"  placeholder="输入小区名称" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="4" class="red">行政区划*</el-col>
                <el-col :span="10">
                    <el-cascader style="width:100%" v-model="data_for_edit.area" :props="cascader_config" size="small" ref="areas1" @change="this.$refs.areas1.togglePopperVisible()" placeholder="点击选择"></el-cascader>
                </el-col>
                <el-col :span="4" :offset="1" class="red">地理位置*</el-col>
                <el-col :span="5">
                    <el-button :type="has_location" icon="el-icon-map-location" plain size="small" @click="click_edit_map" :disabled="true">地图选点</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="4" class="red">小区地址*</el-col>
                <el-col :span="20">
                    <el-input v-model="data_for_edit.address"  placeholder="地图选点后自动填写" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="4" class="red">小区规模*</el-col>
                <el-col :span="20">
                    <el-radio-group v-model="data_for_edit.size">
                        <el-radio v-for="(item,idx) in sizes" :key="idx" :label="item.key">{{ item.size }}</el-radio>
                    </el-radio-group>
                    <div class="fee_memo" v-if="data_for_edit.size" v-html="'服务费：'+sizes[data_for_edit.size].fee+'/年，赠送短信：'+sizes[data_for_edit.size].sms+'条'"></div>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="4">联系人</el-col>
                <el-col :span="20">
                    <el-input v-model="data_for_edit.contacts"  placeholder="输入物业联系人姓名" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="4">联系电话</el-col>
                <el-col :span="20">
                    <el-input v-model="data_for_edit.phone"  placeholder="输入物业联系电话" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->
        <!-- 开通框 -->
        <el-dialog width="400px" custom-class="edit_dialog" title="缴费开通" v-model="show_open" :close-on-click-modal="false">
            <el-row :gutter="10" class="label">
                <el-col :span="24" style="font-size:18px">正在为<span style="padding:0 5px" class="red" v-html="data_for_edit.name"></span>开通服务</el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">小区规模：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].size"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">开通费用：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].fee+'元(含1年维护，'+sizes[data_for_edit.size].sms+'条短信)'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">次年维护费：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].fee*0.2+'元/年(含'+sizes[data_for_edit.size].sms+'条短信)'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">赠送短信：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].sms+'条'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="19" class="red">确定已收取 {{sizes[data_for_edit.size].fee}} 元，开通服务？</el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_open_done">确定开通</el-button>
                <el-button @click="show_open = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 开通框结束 -->
        <!-- 续费框 -->
        <el-dialog width="400px" custom-class="edit_dialog" title="续交维护费" v-model="show_renew" :close-on-click-modal="false">
            <el-row :gutter="10" class="label">
                <el-col :span="24" style="font-size:18px">正在为<span style="padding:0 5px" class="red" v-html="data_for_edit.name"></span>续交维护费</el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">小区规模：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].size"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">维护费：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].fee*0.2+'元/年(含'+sizes[data_for_edit.size].sms+'条短信)'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="7">赠送短信：</el-col>
                <el-col :span="17"><span class="value" v-html="sizes[data_for_edit.size].sms+'条'"></span></el-col>
            </el-row>
            <el-row :gutter="10" class="label">
                <el-col :span="19" class="red">确定已收取 {{sizes[data_for_edit.size].fee*0.2}} 元，延续1年服务？</el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_renew_done">确定续费</el-button>
                <el-button @click="show_renew = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 续费框结束 -->

        <!-- <el-dialog custom-class="map_dialog" width="90%" title="地图选点" v-model="show_map" :close-on-click-modal="false">
            <Map :prop="map_prop" @location_change="location_change"></Map>
            <template #footer>
                <span v-text="map_prop.address" class="address"></span>
                <el-button type="primary" @click="click_map_done">确 定</el-button>
                <el-button @click="show_map = false">关 闭</el-button>
            </template>
        </el-dialog> -->

        <div class="title-panel">
            <el-row>
                <el-col :span="18">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">小区管理</div><div class="descript">管理接入系统的小区，各小区之间完全隔离，互不可见</div></div>
                </el-col>
                <el-col :span="6" class="btn-col">
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加小区</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <!-- 筛选条 -->
        <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px 5px;">
            
            <!-- <el-select class="role" v-model="type" placeholder="过滤小区类型"  size="small" clearable>
                <el-option v-for="(item, idx) in types" :key="idx" :label="item" :value="idx"></el-option>
            </el-select>
            <div>&nbsp;</div> -->
            <el-cascader style="width:280px;" v-model="area" :options="areas" :props="cascader_config" clearable size="small" ref="areas" @change="this.$refs.areas.togglePopperVisible()" placeholder="过滤行政区划"></el-cascader>
            <div>&nbsp;</div>
            <!--
            <el-select class="role" v-model="power" placeholder="拥有权限"  size="small" clearable>
                <el-option v-for="(item, idx) in powers" :key="idx" :label="item" :value="idx"></el-option>
            </el-select>
            <div>&nbsp;</div>
            -->
            <el-input style="width:250px" class="keyword" size="small" v-model="key" placeholder="搜索小区名称、联系人、联系电话" clearable></el-input> 
            <div style="flex-grow:1">&nbsp;</div>
            <el-pagination background layout="total, prev, pager, next" :page-size="size" :current-page="page" :total="total" @current-change="page_change"></el-pagination>
        </el-row>
        <!-- 筛选条结束 -->
        <div class="table-box" id="table-box">
            <el-table :max-height="table_height" :data="list" :stripe="true" border :row-class-name="tableRowClassName">
                <!-- <el-table-column prop="creator_name" label="录入人" align="center" width="100"></el-table-column> -->
                <el-table-column prop="name" label="小区名称" width="150" :overflow-show-tips="true"></el-table-column>
                <el-table-column label="所在地址" width="300" :overflow-show-tips="true">
                	<template #default="scope">
                		<i v-if="scope.row.lng && scope.row.lat" class="el-icon-map-location btn_location" @click="click_show_map(scope.$index)"></i>
                        <span v-html="'【'+scope.row.area_name+'】'"></span>
                		<span v-html="scope.row.address"></span>
                    </template>
                </el-table-column>
                <el-table-column label="联系方式" width="180" align="center">
                    <template #default="scope">
                        <span v-html="scope.row.contacts"></span>
                        <span v-if="scope.row.phone != ''" v-html="'（'+scope.row.phone+'）'"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="open_at" label="开通日期" align="center" width="120" :formatter="format_date"></el-table-column>
                <el-table-column prop="expire_at" label="服务到期" align="center" width="120" :formatter="format_date"></el-table-column>
                <el-table-column label="短信余量/总量" align="center" width="130">
                    <template #default="scope">
                        <span v-html="scope.row.sms_balance+' / '+scope.row.sms_total"></span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" min-width="155" label="操作">
                    <template #default="scope">
                        <el-button v-if="scope.row.open_at==0" @click="click_open(scope.$index)" type="text" size="small">开通</el-button>
                        <el-button v-else @click="click_renew(scope.$index)" type="text" size="small">续费</el-button>
                        <el-button v-if="scope.row.block==0" @click="click_block(scope.$index)" type="text" size="small">停用</el-button>
                        <el-button v-else @click="click_block(scope.$index)" type="text" size="small">启用</el-button>
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
</template>
<script>
// import Map from '../components/Bmap.vue'
import format_timestamp from "../plugins/utils.js"
export default {
  //components:{Map},
  data:function(){
    return {
        area:'',
        areas:[],
        sizes:{},
        key:'',
        page:1,
        size:10,
        total:0,
        list:[],
        show_edit:false,
        data_for_edit:{},
        show_map:false,
        map_prop:{},
        show_open:false,
        show_renew:false,
        data_from_map:{},
        table_height:800,
        cascader_config:{
            expandTrigger:'hover',
            checkStrictly:true,
			lazy: true,
			lazyLoad:(node, resolve)=>{
				this.axios.post("/api/area/list",{id:node.value || 0}).then(ret=>{
					if(!ret.ok){ this.$message.error(ret.msg); return;}
					let nodes = [];
					ret.data.map(item=>{
						nodes.push({value:item.id, label:item.name, leaf:item.hasChildren<1});
					});
					resolve(nodes);
				});
			}
        },//cascader_config
    }
  },//data end
  mounted:function(){
    this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
    this.init();
  },//mounted end
  computed:{
    has_location:function(){
        return this.data_for_edit.lng && this.data_for_edit.lat ? 'success' : 'info';
    }
  },
  watch:{
    page:function(){ this.load_list(); },//page
    area:function(){ this.page=1; this.load_list(); },//area
    key:function(){ this.page=1; this.load_list(); },//key
  },//watch
  methods:{
    init: function(){
        this.load_size();
        this.load_list();
    },//init
    load_size:function(){
        this.axios.post("/api/estate/size_and_fee").then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            ret.data.map(item=>{
                this.sizes[item.key] = {key:item.key, size:item.size, fee:item.fee, sms:item.sms}
            })
            //this.sizes = ret.data;
        });
    },//load_size
    load_list:function(){
      this.axios.post("/api/estate/list",{page:this.page, size:this.size, area:this.area, key:this.key}).then(ret=>{
        if(!ret.ok){this.$message.error(ret.msg); return;}
        ret.data.list.map(item=>{ item.type += ''; });
        this.list = ret.data.list;
        this.total = ret.data.total;
      });
    },//load_list
    tableRowClassName:function({row}){
        if(row.block == 1){ return 'block';}
        if(row.expired == 1){ return 'expired'; }
        return '';
    },//tableRowClassName
    format_date:function(row,col,val){
        return format_timestamp(val);
    },//format_date
    page_change:function(page){ this.page = page; },//page_change
    click_block:function(idx){
        this.axios.post("/api/estate/block",{id:this.list[idx].id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success('操作成功');
            this.list[idx].block = 1 - this.list[idx].block;
            this.$emit('emit','estate_change');
        });
    },//click_top
    click_del:function(idx){
        this.$confirm('此操作将永久删除该小区, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.axios.post("/api/estate/del",{id:this.list[idx].id}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.list.splice(idx,1);
                this.$emit('emit','estate_change');
            }).catch((err) => { console.error(err); });
        }).catch((e) => { console.error(e); });
    },//click_del end
    click_add:function(){
        this.show_edit = true;
        this.data_for_edit = {
            id:0,
            name:'',
            area:[],
            lng:'',
            lat:'',
            contacts:"",
            phone:"",
            address:'',
            size:'',
        };
    },//click_add
    click_edit:function(row){
        this.show_edit = true;
        this.data_for_edit = JSON.parse(JSON.stringify(row));
        this.data_for_edit.area = this.data_for_edit.area_path.split(",").map(item=>{return parseInt(item)});
    },//click_edit end
    click_open:function(idx){
        this.show_open = true;
        this.data_for_edit = JSON.parse(JSON.stringify(this.list[idx]));
    },//click_open
    click_open_done:function(){
        this.axios.post("/api/estate/open",{id:this.data_for_edit.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success(ret.msg);
            this.show_open = false;
            this.load_list();
        });
    },//click_open_done
    click_renew:function(idx){
        this.show_renew = true;
        this.data_for_edit = JSON.parse(JSON.stringify(this.list[idx]));
    },//click_renew
    click_renew_done:function(){
        this.axios.post("/api/estate/renew",{id:this.data_for_edit.id}).then(ret=>{
            if(!ret.ok){this.$message.error(ret.msg); return;}
            this.$message.success(ret.msg);
            this.show_renew = false;
            this.load_list();
        });
    },//click_renew_done
    click_edit_done:function(){
		if(this.data_for_edit.name == '' ){ this.$message.error('请输入小区名称'); return; }
		if(this.data_for_edit.area == '' ){ this.$message.error('请选择小区所属区划'); return; }
        if(this.data_for_edit.lng == '' || this.data_for_edit.lat == '' ){ this.$message.error('请在地图选点或定位'); return; }
        if(this.data_for_edit.address == '' ){ this.$message.error('请填写小区地址'); return; }
        if(this.data_for_edit.size == '' || false == Object.keys(this.sizes).includes(''+this.data_for_edit.size)){ this.$message.error('请选择小区规模'); return; }
		this.axios.post("/api/estate/edit",this.data_for_edit).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
			this.show_edit = false;
			this.load_list();
            this.$emit('emit','estate_change');
		});
    },//click_edit_done
    click_edit_map:function(){
    	this.map_prop = {
    		lng:this.data_for_edit.lng,
    		lat:this.data_for_edit.lat,
    		zoom:16,
    		address:this.data_for_edit.address,
    		allow_edit:true
    	}
        this.show_map = true;
    },//click_map
    click_show_map:function(idx){
        return;
    	this.map_prop = {
    		lng:this.list[idx].lng,
    		lat:this.list[idx].lat,
    		zoom:17,
    		address:this.list[idx].address,
    		allow_edit:false
    	}
        this.show_map = true;
    },//click_map
    click_map_done:function(){
    	this.show_map = false;
    	if(!this.map_prop.allow_edit){return;}
    	this.data_for_edit.lng = this.map_prop.lng;
    	this.data_for_edit.lat = this.map_prop.lat;
    	this.data_for_edit.address = this.map_prop.address;
    },//click_map_done
    location_change:function(val){
    	this.map_prop.lng = val.lng;
    	this.map_prop.lat = val.lat;
    	this.map_prop.address = val.address;
    }
  },//methods end
};
</script>